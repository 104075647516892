import React, { useState } from "react"
import { useIntl, Link } from "gatsby-plugin-intl"
import { gql, useMutation } from '@apollo/client';

import Icon from "../images/assets/logo.svg"
import Dropdown from "../components/common/dropdown"
import _ from "lodash";

const SIGN_UP = gql`
mutation($data: RegisterClientInput!) {
    registerClient(data: $data) {
        id
        websiteUrl
    }
}
`;

// const CHECK_KVK = gql`
// mutation($data: CheckKvkInput!) {
//     checkKvk(data: $data) {
//         valid
//         message
//         name
//         addresses {
//             street
//             houseNumber
//             postCode
//             additive
//             city
//             province
//             country
//         }
//     }
// }
// `


const SignUp = () => {
    const intl = useIntl()

    const [open, setOpen] = useState(1)
    const [errorMessage, setErrorMessage] = useState([])
    const [postMessage, setPostMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [verified, setVerified] = useState(false)
    const [verifiedData, setVerifiedData] = useState()
    const [chosenAddress, setChosenAddress] = useState(0)

    // Association details
    const [assoName, setAssoName] = useState("")
    const [kvkNumber, setKvkNumber] = useState("")
    const [rsin, setRsin] = useState("")
    const [knsaNumber, setKnsaNumber] = useState("")

    // Contact person details
    const [contactEmail, setContactEmail] = useState("")
    const [contactFirstName, setContactFirstName] = useState("")
    const [contactLastName, setContactLastName] = useState("")
    const [contactPhoneNumber, setContactPhoneNumber] = useState("")

    // Billing details
    const [samePerson, setSamePerson] = useState(false)
    const [billingcontactEmail, setBillingContactEmail] = useState("")
    const [billingContactName, setBillingContactName] = useState("")
    const [billingContactPhoneNumber, setBillingContactPhoneNumber] = useState("")

    // Native application download
    const [selectedIos, setSelectedIos] = useState(false)
    const [selectedAndroid, setSelectedAndroid] = useState(false)
    const [iosEmail, setIosEmail] = useState("")
    const [androidEmail, setAndroidEmail] = useState("")

    const [clientSignUp] = useMutation(SIGN_UP);
    // const [checkKvk] = useMutation(CHECK_KVK);

    const addMessage = (message, list) => {
        if (!errorMessage.includes(message)) {
            list.push(message)
        }
    }
    const removeMessage = (message, list) => {
        if (errorMessage.includes(message)) {
            _.pull(list, message)
        }
    }

    const validateInput = async () => {
        let isValid = true
        let tmpMessages = [...errorMessage]
        switch (open) {
            // Add and remove from a temporary list and then change the errorMessage list at the end
            case 1:
                if (kvkNumber === "" || rsin === "" || knsaNumber === "" || assoName === "") {
                    addMessage(intl.formatMessage({ id: "sign-up.validation.text" }), tmpMessages)
                } else if (kvkNumber !== "" && rsin !== "" && knsaNumber !== "") {
                    removeMessage(intl.formatMessage({ id: "sign-up.validation.text" }), tmpMessages)
                }
                if (kvkNumber !== "" && kvkNumber.length !== 8) {
                    addMessage(intl.formatMessage({ id: "sign-up.validation.kvkNumber" }), tmpMessages)
                } else if (kvkNumber !== "" && kvkNumber.length === 8) {
                    removeMessage(intl.formatMessage({ id: "sign-up.validation.kvkNumber" }), tmpMessages)
                }
                if (knsaNumber !== "" && knsaNumber.length > 10) {
                    addMessage(intl.formatMessage({ id: "sign-up.validation.knsaNumber" }), tmpMessages)
                } else if (knsaNumber !== "" && knsaNumber.length <= 10) {
                    removeMessage(intl.formatMessage({ id: "sign-up.validation.knsaNumber" }), tmpMessages)
                }
                if (!verified && (tmpMessages.length === 0 || tmpMessages.includes(intl.formatMessage({ id: "sign-up.validation.noAssociation" })) || tmpMessages.includes(intl.formatMessage({ id: "sign-up.validation.rsinMismatch" })) || tmpMessages.includes((intl.formatMessage({ id: "sign-up.validation.notAssociation" })) || tmpMessages.includes(intl.formatMessage({ id: "sign-up.validation.alreadyPresent" }))))) {
                    // const checkKvkRes = await checkKvk({
                    //     variables: {
                    //         data: {
                    //             kvkNumber,
                    //             rsin
                    //         }
                    //     }
                    // })
                    // if (!checkKvkRes.data.checkKvk.valid) {
                    //     removeMessage(intl.formatMessage({ id: "sign-up.validation.noAssociation" }), tmpMessages)
                    //     removeMessage(intl.formatMessage({ id: "sign-up.validation.rsinMismatch" }), tmpMessages)
                    //     removeMessage(intl.formatMessage({ id: "sign-up.validation.notAssociation" }), tmpMessages)
                    //     removeMessage(intl.formatMessage({ id: "sign-up.validation.alreadyPresent" }), tmpMessages)
                    //     addMessage(intl.formatMessage({ id: `sign-up.validation.${checkKvkRes.data.checkKvk.message}` }), tmpMessages)
                    //     setVerifiedData(null)
                    //     setVerified(false)
                    // } else {
                        removeMessage(intl.formatMessage({ id: "sign-up.validation.noAssociation" }), tmpMessages)
                        removeMessage(intl.formatMessage({ id: "sign-up.validation.rsinMismatch" }), tmpMessages)
                        removeMessage(intl.formatMessage({ id: "sign-up.validation.notAssociation" }), tmpMessages)
                        removeMessage(intl.formatMessage({ id: "sign-up.validation.alreadyPresent" }), tmpMessages)
                        // setVerifiedData(checkKvkRes.data.checkKvk)
                        setVerified(true)
                    // }
                }
                if (tmpMessages.length > 0) {
                    isValid = false
                    setErrorMessage(tmpMessages)
                } else {
                    setErrorMessage([])
                }
                return isValid
            case 2:
                if (contactEmail === "" || contactFirstName === "" || contactLastName === "" || contactPhoneNumber === "") {
                    addMessage(intl.formatMessage({ id: "sign-up.validation.text" }), tmpMessages)
                } else if (contactEmail !== "" && contactFirstName !== "" && contactLastName !== "" && contactPhoneNumber !== "") {
                    removeMessage(intl.formatMessage({ id: "sign-up.validation.text" }), tmpMessages)
                }
                if (contactEmail !== "" && !String(contactEmail).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    addMessage(intl.formatMessage({ id: "sign-up.validation.email" }), tmpMessages)
                } else if (contactEmail !== "" && String(contactEmail).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    removeMessage(intl.formatMessage({ id: "sign-up.validation.email" }), tmpMessages)
                }
                if (contactPhoneNumber !== "" && !String(contactPhoneNumber).toLowerCase().match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)) {
                    addMessage(intl.formatMessage({ id: "sign-up.validation.phone" }), tmpMessages)
                } else if (contactPhoneNumber !== "" && String(contactPhoneNumber).toLowerCase().match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)) {
                    removeMessage(intl.formatMessage({ id: "sign-up.validation.phone" }), tmpMessages)
                }
                if (tmpMessages.length > 0) {
                    isValid = false
                    setErrorMessage(tmpMessages)
                } else {
                    setErrorMessage([])
                }
                return isValid;
            case 3:
                if (billingContactName === "" || billingcontactEmail === "" || billingContactPhoneNumber === "") {
                    addMessage(intl.formatMessage({ id: "sign-up.validation.text" }), tmpMessages)
                } else if (billingContactName !== "" && billingcontactEmail !== "" && billingContactPhoneNumber !== "") {
                    removeMessage(intl.formatMessage({ id: "sign-up.validation.text" }), tmpMessages)
                }
                if (billingcontactEmail !== "" && !String(billingcontactEmail).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    addMessage(intl.formatMessage({ id: "sign-up.validation.email" }), tmpMessages)
                } else if (billingcontactEmail !== "" && String(billingcontactEmail).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    removeMessage(intl.formatMessage({ id: "sign-up.validation.email" }), tmpMessages)
                }
                if (billingContactPhoneNumber !== "" && !String(billingContactPhoneNumber).toLowerCase().match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)) {
                    addMessage(intl.formatMessage({ id: "sign-up.validation.phone" }), tmpMessages)
                } else if (billingContactPhoneNumber !== "" && String(billingContactPhoneNumber).toLowerCase().match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)) {
                    removeMessage(intl.formatMessage({ id: "sign-up.validation.phone" }), tmpMessages)
                }
                if (tmpMessages.length > 0) {
                    isValid = false
                    setErrorMessage(tmpMessages)
                } else {
                    setErrorMessage([])
                }
                return isValid;
            case 4:
                if(!selectedIos && !selectedAndroid) {
                    addMessage(intl.formatMessage({ id: "sign-up.validation.selectNativeOs" }), tmpMessages)
                } else {
                    removeMessage(intl.formatMessage({ id: "sign-up.validation.selectNativeOs" }), tmpMessages)
                }
                if((selectedIos && iosEmail === "") || (selectedAndroid && androidEmail === "")) {
                    addMessage(intl.formatMessage({ id: "sign-up.validation.emailRequired" }), tmpMessages)
                } else {
                    removeMessage(intl.formatMessage({ id: "sign-up.validation.emailRequired" }), tmpMessages)
                }
                if (tmpMessages.length > 0) {
                    isValid = false
                    setErrorMessage(tmpMessages)
                } else {
                    setErrorMessage([])
                }
                return isValid;
            default:
                return false;
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!verified) {
                setPostMessage(intl.formatMessage({ id: "sign-up.validation.error1" }));
                setShowSuccessMessage(true)
            }
            const result = await clientSignUp({
                variables:
                {
                    data: {
                        name: assoName,
                        kvkNumber,
                        knsaNumber,
                        contactEmail,
                        contactName: `${contactFirstName} ${contactLastName}`,
                        contactNumber: contactPhoneNumber,
                        billingEmail: billingcontactEmail,
                        billingContactName,
                        billingContactPhone: billingContactPhoneNumber,
                        verified: true,
                        admin: {
                            email: contactEmail,
                            firstName: contactFirstName,
                            lastName: contactLastName,
                            mobile: contactPhoneNumber
                        },
                        address: {
                            street: "",
                            houseNumber: 1,
                            additive: "",
                            postCode: "",
                            city: "",
                            province: "",
                            country: ""
                        },
                        native: {
                            enableIos: selectedIos,
                            enableAndroid: selectedAndroid,
                            iosEmail,
                            androidEmail
                        },
                        tier: 3
                    }
                }
            });
            setKvkNumber("");
            setKnsaNumber("");
            setContactEmail("");
            setContactFirstName("")
            setContactLastName("")
            setContactPhoneNumber("")
            setBillingContactEmail("")
            setBillingContactName("")
            setBillingContactPhoneNumber("")

            if (result && result.data.registerClient.id) {
                setPostMessage(intl.formatMessage({ id: "sign-up.validation.success" }));
                setShowSuccessMessage(true)
            } else {
                setPostMessage(intl.formatMessage({ id: "sign-up.validation.error1" }));
                setShowSuccessMessage(true)

            }
        } catch (err) {
            setPostMessage(intl.formatMessage({ id: "sign-up.validation.error1" }));
            setShowSuccessMessage(true)
        }
    }

    return (
        <div>
            <Link style={{ display: "flex", marginLeft: 30, marginTop: 30 }} to="/">
                <Icon className="header-logo" style={{ height: 80, width: 80 }} />
            </Link>
            {!showSuccessMessage ? <div className="signup-wrapper">
                <h2 className="header" style={{ paddingTop: "30px" }}>{intl.formatMessage({ id: "sign-up.form.create" })}</h2>

                <div style={{ width: "100%", marginTop: 20 }}>
                    <Dropdown open={open === 1} title={intl.formatMessage({ id: "sign-up.form.associationInformation" })} id={1}>
                        <div className="contact-form">
                            <div className="contact-input-wrapper">
                                <label htmlFor="asso-name">{intl.formatMessage({ id: "sign-up.form.associationName" })}</label>
                                <input
                                    id="asso-name"
                                    type="text"
                                    name="assoName"
                                    value={assoName}
                                    onChange={(e) => setAssoName(e.target.value)} />
                            </div>
                            <div className="contact-input-wrapper">
                                <label htmlFor="kvk-number">{intl.formatMessage({ id: "sign-up.form.kvkNumber" })}</label>
                                <input
                                    id="kvk-number"
                                    type="text"
                                    name="kvkNumber"
                                    value={kvkNumber}
                                    onChange={(e) => setKvkNumber(e.target.value)} />
                            </div>
                            <div className="contact-input-wrapper">
                                <label htmlFor="rsin">RSIN</label>
                                <input
                                    id="rsin"
                                    type="text"
                                    name="rsin"
                                    value={rsin}
                                    onChange={(e) => setRsin(e.target.value)} />
                            </div>
                            <div className="contact-input-wrapper">
                                <label htmlFor="knsa-number">{intl.formatMessage({ id: "sign-up.form.knsaNumber" })}</label>
                                <input
                                    id="knsa-number"
                                    type="text"
                                    name="knsaNumber"
                                    value={knsaNumber}
                                    onChange={(e) => setKnsaNumber(e.target.value)} />
                            </div>
                            <div className="signup-navigate">
                            <button className="btn-proceed" onClick={async () => {
                                const valid = await validateInput()
                                if (valid) {
                                    setOpen(2)
                                }
                            }}>{intl.formatMessage({ id: "sign-up.nav.next" })}</button>
                            </div>
                        </div>
                    </Dropdown>
                    <Dropdown open={open === 2} title={intl.formatMessage({ id: "sign-up.form.contactPersonDetails" })} id={2}>
                        <div className="contact-form">
                            <div className="contact-input-wrapper">
                                <label htmlFor="email">{intl.formatMessage({ id: "sign-up.form.billingEmail" })}</label>
                                <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    value={contactEmail}
                                    onChange={(e) => setContactEmail(e.target.value)} />
                            </div>
                            <div className="contact-input-wrapper">
                                <label htmlFor="first-name">{intl.formatMessage({ id: "sign-up.form.firstName" })}</label>
                                <input
                                    id="first-name"
                                    type="text"
                                    name="firstName"
                                    value={contactFirstName}
                                    onChange={(e) => setContactFirstName(e.target.value)} />
                            </div>
                            <div className="contact-input-wrapper">
                                <label htmlFor="last-name">{intl.formatMessage({ id: "sign-up.form.lastName" })}</label>
                                <input
                                    id="last-name"
                                    type="text"
                                    name="lastName"
                                    value={contactLastName}
                                    onChange={(e) => setContactLastName(e.target.value)} />
                            </div>
                            <div className="contact-input-wrapper">
                                <label htmlFor="phone-number">{intl.formatMessage({ id: "sign-up.form.phone" })}</label>
                                <input
                                    id="phone-number"
                                    type="text"
                                    name="phoneNumber"
                                    value={contactPhoneNumber}
                                    onChange={(e) => setContactPhoneNumber(e.target.value)} />
                            </div>
                            <div className="signup-navigate">
                                <button className="btn-back" onClick={() => { setErrorMessage([]); setOpen(1) }}>{intl.formatMessage({ id: "sign-up.nav.back" })}</button>
                                <button className="btn-proceed" onClick={async () => {
                                    const valid = await validateInput()
                                    if (valid) {
                                        setOpen(3)
                                    }
                                }}>{intl.formatMessage({ id: "sign-up.nav.next" })}</button>
                            </div>
                        </div>
                    </Dropdown>
                    <Dropdown open={open === 3} title={intl.formatMessage({ id: "sign-up.form.billingDetails" })} id={3}>
                        <div className="contact-form">
                            <div className="contact-input-wrapper">
                                <label htmlFor="billing-email">{intl.formatMessage({ id: "sign-up.form.billingEmail" })}</label>
                                <input
                                    id="billing-email"
                                    type="text"
                                    name="billing-email"
                                    value={billingcontactEmail}
                                    onChange={(e) => setBillingContactEmail(e.target.value)} />
                            </div>
                            <div className="contact-input-wrapper">
                                <label htmlFor="billing-contact-name">{intl.formatMessage({ id: "sign-up.form.billingContact" })}</label>
                                <input
                                    id="billing-contact-name"
                                    type="text"
                                    name="billingContactName"
                                    value={billingContactName}
                                    onChange={(e) => setBillingContactName(e.target.value)} />
                            </div>
                            <div className="contact-input-wrapper">
                                <label htmlFor="billing-phone-number">{intl.formatMessage({ id: "sign-up.form.billingPhoneNumber" })}</label>
                                <input
                                    id="billing-phone-number"
                                    type="text"
                                    name="billingPhoneNumber"
                                    value={billingContactPhoneNumber}
                                    onChange={(e) => setBillingContactPhoneNumber(e.target.value)} />
                            </div>
                            <div className="signup-navigate">
                                <button className="btn-back" onClick={() => { setErrorMessage([]); setOpen(2) }}>{intl.formatMessage({ id: "sign-up.nav.back" })}</button>
                                <button className="btn-proceed" onClick={async () => {
                                    const valid = await validateInput()
                                    if (valid) {
                                        setOpen(4)
                                    }
                                }}>{intl.formatMessage({ id: "sign-up.nav.next" })}</button>
                            </div>
                        </div>
                    </Dropdown>
                    <Dropdown open={open === 4} title={intl.formatMessage({ id: "sign-up.form.applicationDetails" })} id={4}>
                        <div className="contact-form">
                            <div className="sign-up-note">
                                <p>{intl.formatMessage({ id: "sign-up.form.nativeApplicationNote" })}</p>
                            </div>
                            <div className="contact-input-wrapper custom-checkbox">
                                <label htmlFor="enable-ios">{intl.formatMessage({ id: "sign-up.form.enableIos" })}</label>
                                <input type="checkbox" id="enable-ios" name="enable-ios" checked={selectedIos} onChange={() => {
                                    setSelectedIos(!selectedIos)
                                }} />
                            </div>
                            {selectedIos && <div className="contact-input-wrapper" style={{ marginTop: 20 }}>
                                <label htmlFor="ios-email">{intl.formatMessage({ id: "sign-up.form.iosEmail" })}</label>
                                <input
                                    id="ios-email"
                                    type="text"
                                    name="iosEmail"
                                    value={iosEmail}
                                    onChange={(e) => setIosEmail(e.target.value)} />
                            </div>}
                            <div className="contact-input-wrapper custom-checkbox">
                                <label htmlFor="enable-android">{intl.formatMessage({ id: "sign-up.form.enableAndroid" })}</label>
                                <input type="checkbox" id="enable-android" name="enable-android" value="selectedAndroid" checked={selectedAndroid} onChange={() => {
                                    setSelectedAndroid(!selectedAndroid)
                                }} />
                            </div>
                            {selectedAndroid && <div className="contact-input-wrapper" style={{ marginTop: 20 }}>
                                <label htmlFor="android-email">{intl.formatMessage({ id: "sign-up.form.androidEmail" })}</label>
                                <input
                                    id="android-email"
                                    type="text"
                                    name="billingPhoneNumber"
                                    value={androidEmail}
                                    onChange={(e) => setAndroidEmail(e.target.value)} />
                            </div>}
                            <div className="signup-navigate" style={{ marginTop: 30 }}>
                                <button className="btn-back" onClick={() => { setErrorMessage([]); setOpen(2) }}>{intl.formatMessage({ id: "sign-up.nav.back" })}</button>
                                <button className="btn-proceed" onClick={async (e) => {
                                    const valid = await validateInput()
                                    if (valid) {
                                        onSubmit(e)
                                    }
                                }}>{intl.formatMessage({ id: "sign-up.nav.create" })}</button>
                            </div>
                        </div>
                    </Dropdown>
                    {errorMessage.length > 0 && (
                        <div className="field-validation">
                            <ul>
                                {errorMessage.map((msg, index) => <li key={index}>{msg}</li>)}
                            </ul>
                        </div>
                    )}
                </div>
            </div> :
                <div style={{ margin: "auto", textAlign: "center", marginTop: 200 }}>
                    {postMessage}
                    {(postMessage.includes("excuses") || postMessage.includes("inconvenience")) && <Link className="link link-active" to='/#contact-form'> {intl.formatMessage({ id: "sign-up.nav.error2" })}</Link>}
                </div>}
            {/* <img src={graphics} className="signup-graphics" /> */}
            {!showSuccessMessage && <footer>
                <div className="hr" />
                <h3 className="header" style={{ margin: "10px" }}>Dassport</h3>
                <div className="footer-margin-auto">
                    <p>{intl.formatMessage({ id: "sign-up.nav.message1" })} <span><Link className="link link-active" to='/#contact-form'> {intl.formatMessage({ id: "sign-up.nav.message2" })}</Link></span> {intl.formatMessage({ id: "sign-up.nav.message3" })}</p>
                </div>
            </footer>}
        </div>
    )
}

export default SignUp;