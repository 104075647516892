import React from "react"

const Dropdown = ({ title, children, open, id }) => {
    return (
        <div open={open} style={{ marginTop: 10, marginBottom: 30}}>
            <div>
                <div>
                    <p><span className="dropdown-id">{id}</span>{title}</p>
                </div>
            </div>
            <div className={open ? "reveal__content" : "hide__content"}>{children}</div>
        </div>
    )
}

export default Dropdown